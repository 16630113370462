<template>
  <div>
    <BrandTest v-if="!this.$route.query.iframeGame" id="divMarathonTestWrapper"></BrandTest>
    <div v-if="this.$route.query.iframeGame">
      <iframe class="iframeGame" :src="iframeLink"></iframe>
    </div>
  </div>
</template>
<script>
import BrandTest from '@/components/brand/BrandTest/BrandTest.vue';
import GameApi from '@/services/Api/gamification';

export default {
  name: 'PlayAndWinDetail',
  components: {
    BrandTest,
  },
  data() {
    return {
      iframeLink: null,
    };
  },

  beforeMount() {
    if (this.$route.query.iframeGame) {
      GameApi.getIframeGaming().then(res => {
        this.iframeLink = res.data.Data.iframe;
      });
    }
    this.$route.meta.breadcrumb[2].name = this.$route.query.title;

    this.$store.dispatch('app/setRefreshMarathonBanner', true);
  },
};
</script>
<style lang="scss" scoped>
.iframeGame {
  width: 100%;
  height: calc(100vh - 80px);
}
</style>
